<template>
  <div>
    <perseu-card title="Formulário de Fenômeno Tumoral">
      <template #title-left>
        <back-button @click="close" />
      </template>
      <template slot="content">
        <v-form ref="form">
          <v-text-field
            label="Nome"
            v-model.trim="tumoralPhenomenon.name"
            :rules="[$rules.required]"
          />
          <v-textarea
            counter
            maxlength="1000"
            label="Instruções"
            v-model="tumoralPhenomenon.instructions"
          />
          <select-categories
            v-model="tumoralPhenomenon.categories"
            label="Categorias"
            :rules="[$rules.required]"
          />
          <select-lang
            v-model="tumoralPhenomenon.lang"
            :rules="[$rules.required]"
          />
        </v-form>
      </template>
      <template slot="actions">
        <v-btn color="secondary" @click="save">Salvar</v-btn>
      </template>
    </perseu-card>
  </div>
</template>

<script>
import { save } from "@/services/tumoral-phenomena-service.js";

export default {
  components: {
    "select-categories": () => import("@/components/Categories/Select"),
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        categories: [],
      }),
    },
  },
  data: function () {
    return {
      tumoralPhenomenon: this.value,
    };
  },
  methods: {
    async save() {
      try {
        this.$store.dispatch("loading/openDialog");
        if (!this.$refs.form.validate()) return;
        const { data } = await save(this.tumoralPhenomenon);
        this.$toasted.global.defaultSuccess();
        if (this.tumoralPhenomenon.id) {
          this.$emit("updated", data);
        } else {
          this.$emit("created", data);
        }
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    close() {
      this.$emit("closed");
    },
  },
};
</script>

<style></style>
